import React from "react";

const NotFound = () => {
  return (
    <div>
      <center>
        {" "}
        <h1>404 Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </center>
    </div>
  );
};

export default NotFound;
